import styled, { css } from 'styled-components';
import { FlexCenterBoth } from 'components/primitives/flex';

interface Props {
  topOwner?: boolean;
  newOwner?: boolean;
  newCaptain?: boolean;
  newBoat?: boolean;
  iconOnly?: boolean;
  outlined?: boolean;
  small?: boolean;
}

// TODO the same component is in PDP3 -> make it common
export default function Badge({
  topOwner = false,
  newOwner = false,
  newCaptain = false,
  newBoat = false,
  iconOnly = false,
  outlined = false,
  small = false,
  ...other
}: Props) {
  if (!topOwner && !newOwner && !newCaptain && !newBoat) {
    return null;
  }
  return (
    // topOwner trumps newOwner
    <BadgeBase
      small={small}
      topOwner={topOwner}
      newOwner={newOwner && !topOwner}
      newCaptain={newCaptain}
      newBoat={newBoat}
      outlined={outlined}
      {...other}
    >
      {topOwner ? (
        <>
          {small ? <SmallCupIcon /> : <CupIcon />} {!iconOnly && 'TOP OWNER'}
        </>
      ) : (
        newOwner && 'NEW OWNER'
      )}
      {newCaptain && 'NEW CAPTAIN'}
      {newBoat && 'NEW BOAT'}
    </BadgeBase>
  );
}

interface BadgeProps {
  newCaptain: boolean;
  newOwner: boolean;
  topOwner: boolean;
  newBoat: boolean;
  outlined: boolean;
  small: boolean;
}
const BadgeBase = styled(FlexCenterBoth)<BadgeProps>`
  gap: 3px;
  font-size: 10px;
  font-weight: 700;
  line-height: 1;
  padding: 5px 6px;
  border-radius: 4px;

  ${({ small }) =>
    small &&
    css`
      font-size: 7px;
      border-radius: 3px;
      padding: 2px 5px;
    `}

  color: ${({ newCaptain, newOwner, topOwner, newBoat, outlined }) =>
    outlined && getColor(newCaptain, topOwner, newOwner, newBoat)};

  ${({ outlined }) => (outlined ? 'color' : 'background-color')}: ${({ newCaptain, newOwner, topOwner, newBoat }) =>
    getBgOrOutlineColor(newCaptain, topOwner, newOwner, newBoat)};

  border: solid 1px
    ${({ newCaptain, newOwner, topOwner, newBoat }) => getBgOrOutlineColor(newCaptain, topOwner, newOwner, newBoat)};

  ${({ outlined }) => outlined && 'background-color: #fff'};
`;

const getColor = (newCaptain: boolean, topOwner: boolean, newOwner: boolean, newBoat: boolean) => {
  if (newCaptain || newOwner) {
    return '#fff';
  }
  if (topOwner) {
    return '#154353';
  }
  return newBoat && '#001D58';
};

const getBgOrOutlineColor = (newCaptain: boolean, topOwner: boolean, newOwner: boolean, newBoat: boolean) => {
  if (newCaptain) {
    return '#004e60';
  }
  if (topOwner) {
    return '#f4c064';
  }
  if (newOwner) {
    return '#0e7873';
  }
  return newBoat && '#08A97E';
};

const SmallCupIcon = () => (
  <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.41555 0H3.48011H3.51873H5.58329C5.58329 0 5.61328 0.210858 5.6204 0.557129H6.41695L6.42848 0.558541C6.58993 0.578321 6.82288 0.65938 6.93736 0.894651C7.05147 1.12917 7.01091 1.44248 6.80397 1.83748C6.1768 3.03458 5.39118 3.49975 4.8172 3.83961L4.73478 3.88851C4.40164 4.31591 4.09195 4.49464 4.09195 4.49464H4.07962C4.09379 4.78047 4.21193 5.16761 4.60777 5.37817H5.08591V6.30015H1.95025V5.37817H2.37108C2.76899 5.1665 2.88629 4.77641 2.89945 4.49016C2.85038 4.46008 2.56636 4.2767 2.26314 3.88731C2.23684 3.87165 2.21002 3.85577 2.18273 3.83961L2.18272 3.83961C1.60874 3.49975 0.823123 3.03458 0.195952 1.83748C-0.0109861 1.44248 -0.0515457 1.12917 0.0625636 0.894651C0.17704 0.65938 0.409992 0.578321 0.57144 0.558541L0.58297 0.557129H1.37845C1.38557 0.210858 1.41555 0 1.41555 0ZM1.38076 0.941286H0.607218C0.51238 0.95517 0.438212 0.997386 0.406114 1.06336C0.372471 1.1325 0.347845 1.30258 0.534342 1.65856C0.939713 2.43231 1.39578 2.85851 1.82959 3.16173C1.75316 2.9911 1.68363 2.80194 1.62587 2.59306C1.45575 1.97781 1.39537 1.39678 1.38076 0.941286ZM5.37298 2.59306C5.54309 1.97781 5.60347 1.39678 5.61809 0.941286H6.3927C6.48754 0.95517 6.56171 0.997386 6.59381 1.06336C6.62745 1.1325 6.65208 1.30258 6.46558 1.65856C6.05972 2.43324 5.60304 2.85953 5.16877 3.16282C5.24539 2.9919 5.3151 2.80238 5.37298 2.59306Z"
      fill="#154353"
    />
  </svg>
);

function CupIcon() {
  return (
    <svg width="12" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.39.292h6.452s.047.325.058.859h1.233l.017.002c.25.03.61.155.788.518.177.361.114.844-.206 1.453-.971 1.844-2.187 2.561-3.076 3.085l-.127.075c-.516.659-.995.934-.995.934h-.02c.023.44.206 1.037.818 1.361h.74V10H3.218V8.58h.652c.616-.326.797-.927.818-1.368-.076-.046-.516-.328-.986-.929l-.124-.073c-.889-.524-2.105-1.24-3.075-3.085C.183 2.515.119 2.032.296 1.67c.177-.363.538-.487.788-.518l.018-.002h1.231c.011-.534.057-.859.057-.859Zm-.053 1.45H1.139c-.147.022-.261.087-.311.189-.052.106-.09.369.198.917.628 1.192 1.334 1.849 2.005 2.316a6.131 6.131 0 0 1-.315-.876 10.905 10.905 0 0 1-.38-2.545Zm6.18 2.546c.263-.948.356-1.843.379-2.545h1.199c.147.021.262.086.311.188.052.106.09.369-.198.917C9.58 4.042 8.873 4.698 8.2 5.166a6.13 6.13 0 0 0 .316-.878Z"
        fill="#154353"
      />
    </svg>
  );
}
