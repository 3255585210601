import { FlexAlignCenter } from 'components/primitives/flex';
import { Text } from 'components/primitives/typography';
import { useMemo } from 'react';
import styled from 'styled-components';

interface Props {
  rating: number;
  reviewCount: number;
  singleStar?: boolean;
  countText?: string;
  hideCountText?: boolean;
  ratingFontSize?: string;
  countFontSize?: string;
  countFontWeight?: string | number;
  ratingFontWeight?: string | number;
  elementsGap?: string;
}

// TODO the same component is in PDP3 -> make it common
export default function StarRating({
  rating = 0,
  reviewCount = 0,
  singleStar = false,
  countText = 'booking',
  hideCountText = false,
  ratingFontSize = '14px',
  countFontSize = '14px',
  ratingFontWeight = 600,
  countFontWeight = 600,
  elementsGap = '4px',
}: Props) {
  return (
    <FlexAlignCenter gap={elementsGap}>
      {singleStar ? <Star percentage={100} /> : <Stars rating={rating} />}
      <RatingInfo gap={elementsGap}>
        <Text fSize={ratingFontSize} fWeight={ratingFontWeight}>
          {rating.toPrecision(2)}
        </Text>
        <Text fSize={countFontSize} color="#8e9697" fWeight={countFontWeight}>
          ({reviewCount}
          {hideCountText ? '' : ` ${countText}${reviewCount > 1 ? 's' : ''}`})
        </Text>
      </RatingInfo>
    </FlexAlignCenter>
  );
}

export function Stars({ rating }) {
  const starsArray = useMemo(() => {
    const fullStarsCount = Math.floor(rating);
    const partialStarRatingAmount = rating - fullStarsCount;
    const emptyStarsCount = (partialStarRatingAmount > 0 ? 4 : 5) - fullStarsCount;

    return [
      ...Array(fullStarsCount).fill(100),
      ...(partialStarRatingAmount > 0 ? [partialStarRatingAmount * 100] : []),
      ...Array(emptyStarsCount).fill(0),
    ];
  }, [rating]);

  return (
    <FlexAlignCenter>
      {starsArray.map((percentage, index) => (
        <Star key={index} percentage={percentage} />
      ))}
    </FlexAlignCenter>
  );
}

const RatingInfo = styled(FlexAlignCenter)`
  line-height: 1;
`;

function Star({ percentage }) {
  const defs = (
    <defs>
      <linearGradient id={`grad${percentage}`} x1="0%" y1="0%" x2="100%" y2="0%">
        <stop
          offset={`${percentage}%`}
          style={{
            stopColor: '#72D4BA',
            stopOpacity: 1,
          }}
        />
        <stop
          offset={`${percentage}%`}
          style={{
            stopColor: '#DBDFE5',
            stopOpacity: 1,
          }}
        />
      </linearGradient>
    </defs>
  );

  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" width="14" height="14">
      {defs}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.998 10.493a.397.397 0 0 0-.462 0l-2.952 2.105a.397.397 0 0 1-.614-.43l1.01-3.667a.397.397 0 0 0-.132-.413L.962 5.728a.397.397 0 0 1 .238-.704L4.813 4.9a.397.397 0 0 0 .363-.268L6.39 1.094a.397.397 0 0 1 .752 0l1.215 3.538a.397.397 0 0 0 .363.268l3.613.124a.397.397 0 0 1 .238.705L9.686 8.088a.397.397 0 0 0-.132.413l1.01 3.668c.1.36-.31.646-.614.429l-2.952-2.105Z"
        fill={`url(#grad${percentage})`}
      />
    </svg>
  );
}
