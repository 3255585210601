import queryString from 'query-string';
import { defaultFilterValues, FilterApiPayloadType } from './Filter/filterStore';

// eslint-disable-next-line import/prefer-default-export
export const getBoatCardUrl = ({
  filterApiPayload,
  boatId,
  pos,
}: {
  filterApiPayload?: FilterApiPayloadType;
  boatId: string;
  pos: number;
}) => {
  const filterObject: any = {
    pos,
  };

  if (filterApiPayload) {
    const transformSearchToPdp = {
      no_captain: 'bareboat',
      captain: 'captained',
      '4 hours': 'half_day',
      '2 hours': 'two_hours',
      '3 hours': 'three_hours',
      '6 hours': 'six_hours',
      '8 hours': 'full_day',
    };

    if (filterApiPayload.passengers && filterApiPayload.passengers !== defaultFilterValues.PASSENGERS_COUNT)
      filterObject.min_capactiy = filterApiPayload.passengers;

    if (filterApiPayload.trip_date) filterObject.start_period = filterApiPayload.trip_date;

    if (filterApiPayload.start_date && filterApiPayload.end_date) {
      filterObject.start_period = filterApiPayload.start_date;
      filterObject.end_period = filterApiPayload.end_date;
    }

    if (
      filterApiPayload.package_types &&
      filterApiPayload.package_types.length === 1 &&
      transformSearchToPdp[filterApiPayload.package_types[0]]
    ) {
      filterObject.package_type = transformSearchToPdp[filterApiPayload.package_types[0]];
    }

    if (
      filterApiPayload.durations &&
      filterApiPayload.durations.length === 1 &&
      transformSearchToPdp[filterApiPayload.durations[0]]
    ) {
      filterObject.duration = transformSearchToPdp[filterApiPayload.durations[0]];
    }

    return `/boats/${boatId}?${queryString.stringify(filterObject)}`;
  }
  return `/boats/${boatId}?${queryString.stringify(filterObject)}`;
};
