import OpenApiConfiguration from 'api/OpenApiConfiguration';
import { MutationOptionsCustomType } from 'components/booking/mutationHooks';
import { useMutation } from 'react-query';
import { CaptainBoatsApi } from 'swagger/apis/captain-boats-api';

const captainBoatsApi = new CaptainBoatsApi(OpenApiConfiguration);

export const useCaptainActivateBoatMutation = (
  options: MutationOptionsCustomType<typeof captainBoatsApi.domesticV2CaptainBoatsIdActivatePut, { boatId: string }>
) => useMutation(({ boatId }) => captainBoatsApi.domesticV2CaptainBoatsIdActivatePut(boatId), options);

export const useCaptainDeactivateBoatMutation = (
  options: MutationOptionsCustomType<typeof captainBoatsApi.domesticV2CaptainBoatsIdDeactivatePut, { boatId: string }>
) => useMutation(({ boatId }) => captainBoatsApi.domesticV2CaptainBoatsIdDeactivatePut(boatId), options);
