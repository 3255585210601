import styled from 'styled-components';
import { Box } from 'components/primitives/box';
import { FlexAlignCenter, FlexAlignCenterSpaceBetween, FlexCenterBoth } from 'components/primitives/flex';
import { Text } from 'components/primitives/typography';
import dynamic from 'next/dynamic';
import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useBreakpoint } from 'components/BreakpointProvider';
import { useEffect } from 'react';
import { useFavoriteBoat } from 'components/pdp/v3/hooks';
import { useCaptainActivateBoatMutation, useCaptainDeactivateBoatMutation } from 'hooks/mutationHooks';
import useLazySnackbar from 'components/banners/useLazySnackbar';
import useRudderStackTrack from 'hooks/useRudderStackTrack';
import Swiper from 'swiper';
import { captainOptionsManipulatorAtom, hoveredBoatIdAtom, isCaptainSearchModeAtom } from '../Filter/filterStore';
import StarRating from '../StarRating';
import Badge from '../Badge';

const Gallery = dynamic(() => import('./Gallery'), { ssr: false });

const manipulatedCaptainedBoatsAtom = atom({}, (get, set, boatId: any) => {
  const captainedBoats = get(manipulatedCaptainedBoatsAtom);
  if (captainedBoats[boatId]) {
    set(manipulatedCaptainedBoatsAtom, { ...captainedBoats, [boatId]: false });
  } else {
    set(manipulatedCaptainedBoatsAtom, { ...captainedBoats, [boatId]: true });
  }
});

type Props = {
  // TODO Boat not complete in swagger
  boat: any;
  url: string;
  index: number;
  currentPage?: number;
  searchLocation?: string;
};

export default function BoatCard({
  boat: {
    title,
    boat_public_id,
    photos,
    rating,
    completed_booking_count,
    instant_bookable,
    new_boat,
    is_top_owner,
    display_duration,
    display_price,
    display_price_per_hour,
    display_captain_option,
    location: { display_location },
    boat_captain,
  },
  url,
  index: cardIndex,
  currentPage,
  searchLocation,
}: Props) {
  const rudderTrack = useRudderStackTrack();
  const { enqueueSnackbar } = useLazySnackbar();
  const setHoveredBoatId = useSetAtom(hoveredBoatIdAtom);
  const breakpoints = useBreakpoint();
  const smallScreen = breakpoints.xs || breakpoints.sm;
  const isCaptainSearchMode = useAtomValue(isCaptainSearchModeAtom);
  const setCaptainsOptions = useSetAtom(captainOptionsManipulatorAtom);
  const [manipulatedCaptainedBoats, toggleCaptainedBoat] = useAtom(manipulatedCaptainedBoatsAtom);

  const { isBoatFavorite, addToFavorites } = useFavoriteBoat(boat_public_id);

  const captainActivateBoatMutation = useCaptainActivateBoatMutation({
    onSuccess() {
      toggleCaptainedBoat(boat_public_id);
      enqueueSnackbar('You are now a captain for this boat.', { variant: 'success', autoHideDuration: 3000 });
    },
  });

  const captainDeactivateBoatMutation = useCaptainDeactivateBoatMutation({
    onSuccess() {
      toggleCaptainedBoat(boat_public_id);
      enqueueSnackbar('You are no longer a captain for this boat.', { variant: 'success', autoHideDuration: 3000 });
    },
  });

  const isCaptainActive = manipulatedCaptainedBoats[boat_public_id];

  useEffect(() => {
    if (boat_captain?.status === 'active' && !manipulatedCaptainedBoats[boat_public_id])
      toggleCaptainedBoat(boat_public_id);
  }, [boat_captain?.status]);

  useEffect(() => {
    if (isCaptainSearchMode) {
      setCaptainsOptions('captain');
    }
  }, [isCaptainSearchMode]);

  const handleCancelBeingCaptain = (e) => {
    e.preventDefault();
    captainDeactivateBoatMutation.mutate({ boatId: boat_public_id });
  };

  const handleBecomeCaptain = (e) => {
    e.preventDefault();
    captainActivateBoatMutation.mutate({ boatId: boat_public_id });
  };

  const priceObject = display_price_per_hour
    ? {
        price: display_price_per_hour,
        duration: '/hour',
      }
    : {
        price: display_price,
        duration: null,
      };

  const trackImageChange = (swiper: Swiper) => {
    rudderTrack(currentPage ? 'view_search_boat_photo' : 'view_search_similar_boat_photo', {
      boatId: boat_public_id,
      photoNumber: swiper.activeIndex,
      searchLocation: searchLocation || display_location,
      searchResultsPageRank: currentPage,
      searchResultsBoatRank: cardIndex + 1,
    });
  };

  return (
    <Card
      as="a"
      onMouseEnter={() => setHoveredBoatId(boat_public_id)}
      onMouseLeave={() => setHoveredBoatId(null)}
      href={url}
      target={smallScreen ? '_self' : '_blank'}
      data-testid="search-boat-card"
    >
      <GalleryContainer height="256px">
        <Gallery cardIndex={cardIndex} photos={photos} onChange={trackImageChange} />
        <PriceBadge data-testid="search-boat-card-price-badge">
          <Text fWeight="700" fSize="14px" as="span">
            {priceObject.price}
          </Text>
          {priceObject.duration && (
            <Text fWeight="500" fSize="13px" as="span">
              {priceObject.duration}
            </Text>
          )}
        </PriceBadge>
        {isCaptainSearchMode &&
          (isCaptainActive ? (
            <CaptainButton isCaptain={isCaptainActive} onClick={handleCancelBeingCaptain}>
              Cancel being captain
            </CaptainButton>
          ) : (
            <CaptainButton onClick={handleBecomeCaptain}>Become captain</CaptainButton>
          ))}
      </GalleryContainer>

      {instant_bookable && !isCaptainSearchMode && <InstantBookBadge />}

      <AddToFavoritesButton onClick={addToFavorites} data-testid="search-boat-card-favorite">
        <HeartIcon isFavorite={isBoatFavorite} width={16} />
      </AddToFavoritesButton>

      <BoatInfo padding="14px 10px 16px 18px">
        <FlexAlignCenterSpaceBetween data-testid="search-boat-card-info-top-line">
          <FlexAlignCenter gap="6px">
            {/* top owner badge */}
            {is_top_owner ? <Badge topOwner /> : new_boat && <Badge newBoat={new_boat} outlined />}
            <Text fWeight="600" fSize="10px" color="#8F9697" tTransform="uppercase">
              {/* boat location */}
              {display_location}
            </Text>
          </FlexAlignCenter>
          {/* rating */}
          {rating > 0 && (
            <StarRating
              rating={rating}
              reviewCount={completed_booking_count}
              singleStar
              ratingFontSize="12px"
              countFontSize="12px"
              ratingFontWeight={700}
              countFontWeight={500}
            />
          )}
        </FlexAlignCenterSpaceBetween>

        {/* boat title */}
        <Text fWeight="600" fSize="16px" my="8px" pr="30%" data-testid="search-boat-card-info-title-line">
          {title}
        </Text>

        <Text fWeight="500" fSize="12px" data-testid="search-boat-card-info-bottom-line">
          {display_duration} rental • {display_captain_option}
        </Text>
      </BoatInfo>
    </Card>
  );
}

const Card = styled(Box)`
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  position: relative;
  transition: box-shadow 300ms;
  color: unset !important;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  flex: 1;

  &:hover {
    color: unset;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  }
`;

const GalleryContainer = styled(Box)`
  position: relative;
  border-radius: 15px 15px 0 0;
  overflow: hidden;
  isolation: isolate;
`;

const BoatInfo = styled(Box)``;

const PriceBadge = styled(Box)`
  background-color: #fff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.11);
  border-radius: 4px;
  padding: 4px 6px;
  position: absolute;
  bottom: 14px;
  right: 14px;
  z-index: 1;
`;

const InstantBookBadge = styled((props) => (
  <FlexAlignCenter gap="4px" {...props} data-testid="search-boat-card-instant-book-badge">
    <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx={8} cy={8} r={8} fill="#F4C064" />
      <path
        d="M7.787 9.262H4.32a.32.32 0 0 1-.251-.516l4.68-5.956c.21-.267.635-.065.562.266l-.765 3.44h3.431c.265 0 .415.305.253.515l-4.254 5.53c-.198.258-.61.089-.57-.234l.381-3.045Z"
        fill="#102A5E"
      />
    </svg>
    <Text fWeight="700" fSize="10px" color="#fff" as="span">
      INSTANT BOOK
    </Text>
  </FlexAlignCenter>
))`
  background: #0a4195;
  border-radius: 4px;
  position: absolute;
  top: 12px;
  left: -10px;
  padding: 6px;
`;

const CaptainButton = styled.div<{ isCaptain?: boolean }>`
  position: absolute;
  top: 14px;
  left: 14px;
  z-index: 1;
  background-color: ${({ isCaptain }) => (isCaptain ? '#00acb7' : '#2a8500')};
  border-radius: 6px;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: 700;
  padding: 10px 5px;
  color: #fff;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }
`;

const AddToFavoritesButton = styled(FlexCenterBoth)`
  background: white;
  cursor: pointer;
  border-radius: 50%;
  height: 28px;
  width: 28px;
  transition: transform 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  border: 1px solid #979797;
  position: absolute;
  top: 12px;
  right: 12px;

  &:hover {
    transform: scale(1.1);
  }
`;

// TODO same is in PDP3 / make it common
const HeartIcon = ({ isFavorite, width = 18 }) => (
  <svg viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" width={width}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.078 14.905c.93-.618 7.531-5.142 7.531-9.171 0-3.68-2.999-4.08-3.462-4.071-.142-.008-.287 0-.431.017a4.443 4.443 0 0 0-1.657.557c-.66.369-1.3.895-2.008 1.65a.112.112 0 0 1-.161 0c-.708-.755-1.349-1.281-2.008-1.65a4.444 4.444 0 0 0-1.657-.557 2.544 2.544 0 0 0-.431-.017c-.463-.008-3.462.392-3.462 4.07 0 4.03 6.6 8.554 7.53 9.172a.19.19 0 0 0 .216 0Z"
      stroke={isFavorite ? '#72d4ba' : '#5E696A'}
      fill={isFavorite ? '#72d4ba' : undefined}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
